<template>
  <v-container>
    <v-expansion-panels hover v-model="g_ppdb_riwayat.open_panel" multiple>
      <!-- Start Jenjang SD -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{ $t("ppdb.educational_history.elementary_school_level_sub") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="g_ppdb_riwayat.SD.nama_sekolah"
                :readonly="isEdit"
                name="elementaryname"
                outlined
                dense
                class="mt-4"
                required
              >
                <!-- :rules="
                  !isManual() ? isValid($t('ppdb.rules.school_name_rules')) : []
                " -->
                <template #label
                  >{{ $t("ppdb.educational_history.school_name") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                ref="tahunMasukSD"
                v-model="g_ppdb_riwayat.SD.tahun_masuk"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="elementary_entry_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunMasukSD : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.year_of_entry") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="g_ppdb_riwayat.SD.tahun_lulus"
                :disabled="!(g_ppdb_riwayat.SD.tahun_masuk.length == 4)"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="elementary_graduation_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunLulusSD : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.graduation_year") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <ButtonUpload
                v-if="!isEdit"
                :label="$t('ppdb.educational_history.upload_document')"
                :accept="acceptFile"
                :rules="
                  isValid(
                    [
                      $t('ppdb.rules.upload_documents_rules'),
                      $t('ppdb.rules.upload_documents_type_rules')
                    ],
                    'upload'
                  )
                "
                :loading="dokumenSdLoading"
                class="mb-2"
                multiple
                depressed
                @getDocument="
                  r => {
                    this.dokumenSd = r;
                    this.uploadImage('upload', 'SD');
                  }
                "
              />
              <v-row no-gutters>
                <v-col
                  v-for="(file, index) in g_ppdb_riwayat.SD.dokumen"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="3"
                  class="text-center"
                >
                  <v-chip
                    color="primary"
                    :close="!isEdit"
                    @click="openLink(file)"
                    @click:close="fileDelete(g_ppdb_riwayat.SD.dokumen, file)"
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                  <v-card flat>
                    <v-img
                      style="max-width: 200px"
                      class="mt-2 mx-auto"
                      :src="g_ppdb_riwayat.SD.dokumen[index]"
                      aspect-ratio="1.4"
                      @click="viewImage(g_ppdb_riwayat.SD.dokumen[index])"
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Jenjang SD -->

      <!-- Start Jenjang SMP -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{ $t("ppdb.educational_history.junior_high_school_level_sub") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="g_ppdb_riwayat.SMP.nama_sekolah"
                :readonly="isEdit"
                name="jhs_name"
                outlined
                dense
                class="mt-4"
                required
              >
                <!-- :rules="
                  !isManual() ? isValid($t('ppdb.rules.school_name_rules')) : []
                " -->
                <template #label
                  >{{ $t("ppdb.educational_history.school_name") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                ref="tahunMasukSMP"
                v-model="g_ppdb_riwayat.SMP.tahun_masuk"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="jhs_entry_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunMasukSMP : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.year_of_entry") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="g_ppdb_riwayat.SMP.tahun_lulus"
                :disabled="!(g_ppdb_riwayat.SMP.tahun_masuk.length == 4)"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="jhs_graduation_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunLulusSMP : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.graduation_year") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <ButtonUpload
                v-if="!isEdit"
                :label="$t('ppdb.educational_history.upload_document')"
                :accept="acceptFile"
                :rules="
                  isValid(
                    [
                      $t('ppdb.rules.upload_documents_rules'),
                      $t('ppdb.rules.upload_documents_type_rules')
                    ],
                    'upload'
                  )
                "
                :loading="dokumenSmpLoading"
                class="mb-2"
                multiple
                depressed
                @getDocument="
                  r => {
                    this.dokumenSmp = r;
                    this.uploadImage('upload', 'SMP');
                  }
                "
              />

              <v-row no-gutters>
                <v-col
                  v-for="(file, index) in g_ppdb_riwayat.SMP.dokumen"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="3"
                  class="text-center"
                >
                  <v-chip
                    color="primary"
                    :close="!isEdit"
                    @click="openLink(file)"
                    @click:close="fileDelete(g_ppdb_riwayat.SMP.dokumen, file)"
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>

                  <v-card flat>
                    <v-img
                      style="max-width: 200px"
                      class="mt-2 mx-auto"
                      :src="g_ppdb_riwayat.SMP.dokumen[index]"
                      aspect-ratio="1.4"
                      @click="viewImage(g_ppdb_riwayat.SMP.dokumen[index])"
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Jenjang SMP -->

      <!-- Start Jenjang SMA -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{ $t("ppdb.educational_history.senior_high_school_level_sub") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="g_ppdb_riwayat.SMA.nama_sekolah"
                :readonly="isEdit"
                name="shs_name"
                outlined
                dense
                class="mt-4"
                required
              >
                <!-- :rules="
                  !isManual() ? isValid($t('ppdb.rules.school_name_rules')) : []
                " -->
                <template #label
                  >{{ $t("ppdb.educational_history.school_name") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                ref="tahunMasukSMA"
                v-model="g_ppdb_riwayat.SMA.tahun_masuk"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="shs_entry_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunMasukSMA : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.year_of_entry") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="g_ppdb_riwayat.SMA.tahun_lulus"
                :disabled="!(g_ppdb_riwayat.SMA.tahun_masuk.length == 4)"
                :items="generateYears()"
                :readonly="isEdit"
                item-text="name"
                name="shs_graduation_year"
                maxlength="4"
                required
                outlined
                dense
              >
                <!-- :rules="!isManual() ? ruleTahunLulusSMA : []" -->
                <template #label
                  >{{ $t("ppdb.educational_history.graduation_year") }}
                  <!-- <span v-if="!isManual()" class="red--text"
                    ><strong> *</strong></span
                  > -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <ButtonUpload
                v-if="!isEdit"
                :label="$t('ppdb.educational_history.upload_document')"
                :accept="acceptFile"
                :rules="
                  isValid(
                    [
                      $t('ppdb.rules.upload_documents_rules'),
                      $t('ppdb.rules.upload_documents_type_rules')
                    ],
                    'upload'
                  )
                "
                :loading="dokumenSmaLoading"
                class="mb-2"
                multiple
                depressed
                @getDocument="
                  r => {
                    this.dokumenSma = r;
                    this.uploadImage('upload', 'SMA');
                  }
                "
              />

              <v-row no-gutters>
                <v-col
                  v-for="(file, index) in g_ppdb_riwayat.SMA.dokumen"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="3"
                  class="text-center"
                >
                  <v-chip
                    color="primary"
                    :close="!isEdit"
                    @click="openLink(file)"
                    @click:close="fileDelete(g_ppdb_riwayat.SMA.dokumen, file)"
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                  <v-card flat>
                    <v-img
                      style="max-width: 200px"
                      class="mt-2 mx-auto"
                      :src="g_ppdb_riwayat.SMA.dokumen[index]"
                      aspect-ratio="1.4"
                      @click="viewImage(g_ppdb_riwayat.SMA.dokumen[index])"
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Jenjang SMA -->

      <!-- Start Perguruan Tinggi -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{ $t("ppdb.educational_history.strata_level_sub") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-card
            class="mt-5"
            v-for="(d, i) in g_ppdb_riwayat.strata"
            :key="i"
            outlined
          >
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12">
                    <v-row v-if="!isEdit" class="mb-3" no-gutters justify="end">
                      <v-btn
                        color="pink"
                        icon
                        text
                        v-if="i !== 0"
                        @click="removeStrata(i)"
                        :disabled="isEdit"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </v-row>
                    <v-select
                      v-model="g_ppdb_riwayat.strata[i].jenjang"
                      :items="g_master_data.stratas"
                      :readonly="isEdit"
                      item-value="value"
                      :item-text="
                        g_language == 'en'
                          ? 'description_eng'
                          : 'description_ina'
                      "
                      name="strata_level"
                      outlined
                      dense
                      required
                    >
                      <!-- :rules="[v => !!v || $t('ppdb.rules.strata_rules')]" -->
                      <template #label
                        >{{
                          $t(
                            "ppdb.educational_history.strata_level_data.select_strata_level"
                          )
                        }}
                        <!-- <span class="red--text"><strong> *</strong></span> -->
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="g_ppdb_riwayat.strata[i].asal_institusi"
                      :readonly="isEdit"
                      name="origin_institution"
                      outlined
                      dense
                      required
                    >
                      <!-- :rules="[
                        v =>
                          !!v ||
                          $t('ppdb.rules.origin_of_the_institution_rules')
                      ]" -->
                      <template #label
                        >{{
                          $t(
                            "ppdb.educational_history.strata_level_data.origin_of_the_institutions"
                          )
                        }}
                        <!-- <span class="red--text"><strong> *</strong></span> -->
                      </template>
                    </v-text-field>
                    <v-select
                      v-model="g_ppdb_riwayat.strata[i].akreditas"
                      :readonly="isEdit"
                      :items="
                        $t(
                          'ppdb.educational_history.strata_level_data.list_accreditation'
                        )
                      "
                      item-text="name"
                      item-value="id"
                      name="accreditaion"
                      outlined
                      dense
                      required
                    >
                      <!-- :rules="[ v => !!v || $t('ppdb.rules.accreditation_rules')
                      ]" -->
                      <template #label
                        >{{
                          $t(
                            "ppdb.educational_history.strata_level_data.accreditation"
                          )
                        }}
                        <!-- <span class="red--text"><strong> *</strong></span> -->
                      </template>
                    </v-select>
                    <v-autocomplete
                      v-model="g_ppdb_riwayat.strata[i].tahun_masuk"
                      :items="generateYears()"
                      :readonly="isEdit"
                      item-text="name"
                      maxlength="4"
                      name="year_of_entry"
                      required
                      outlined
                      dense
                    >
                      <!-- :rules="rulesTahunMasukStrata[i]" -->
                      <template #label
                        >{{ $t("ppdb.educational_history.year_of_entry") }}
                        <!-- <span class="red--text"><strong> *</strong></span> -->
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="g_ppdb_riwayat.strata[i].nomor_ijazah"
                      :label="
                        $t(
                          'ppdb.educational_history.strata_level_data.diploma_number'
                        )
                      "
                      :readonly="isEdit"
                      name="diploma_number"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="g_ppdb_riwayat.strata[i].nilai_ipk"
                      :readonly="isEdit"
                      :label="
                        $t(
                          'ppdb.educational_history.strata_level_data.gpa_value'
                        )
                      "
                      :rules="
                        isValid($t('ppdb.rules.gpa_value_valid_rules'), 'gpa')
                      "
                      name="gpa"
                      outlined
                      dense
                      required
                      maxlength="4"
                    >
                    </v-text-field>
                    <!-- :rules="rulesTahunLulusStrata[i]" -->

                    <!-- required -->
                    <v-autocomplete
                      v-model="g_ppdb_riwayat.strata[i].tahun_lulus"
                      :readonly="isEdit"
                      :disabled="
                        g_ppdb_riwayat.strata[i].tahun_masuk.length !== 4
                      "
                      :items="generateYears()"
                      :hint="
                        $t('ppdb.educational_history.graduation_year_hint')
                      "
                      :clearable="isEdit"
                      name="graduation_year"
                      persistent-hint
                      item-text="name"
                      maxlength="4"
                      outlined
                      dense
                    >
                      <template #label
                        >{{ $t("ppdb.educational_history.graduation_year") }}
                        <!-- <span class="red--text"><strong> *</strong></span> -->
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <ButtonUpload
                      v-if="!isEdit"
                      :label="$t('ppdb.educational_history.upload_document')"
                      :accept="acceptFile"
                      :rules="
                        isValid(
                          [
                            $t('ppdb.rules.upload_documents_rules'),
                            $t('ppdb.rules.upload_documents_type_rules')
                          ],
                          'upload'
                        )
                      "
                      :loading="dokumenStrataLoading"
                      class="mb-2"
                      multiple
                      depressed
                      @getDocument="
                        r => {
                          getStrataDocument(r, i);
                        }
                      "
                    />

                    <v-row no-gutters>
                      <v-col
                        v-for="(file, index) in g_ppdb_riwayat.strata[i]
                          .dokumen"
                        :key="index"
                        cols="12"
                        sm="4"
                        md="3"
                        class="text-center"
                      >
                        <v-chip
                          color="primary"
                          :close="!isEdit"
                          @click="openLink(file)"
                          @click:close="
                            fileDelete(g_ppdb_riwayat.strata[i].dokumen, file)
                          "
                        >
                          {{ file.replace(/^.*[\\\/]/, "") }}
                        </v-chip>
                        <v-card flat>
                          <v-img
                            style="max-width: 200px"
                            class="mt-2 mx-auto"
                            :src="g_ppdb_riwayat.strata[i].dokumen[index]"
                            aspect-ratio="1.4"
                            @click="
                              viewImage(g_ppdb_riwayat.strata[i].dokumen[index])
                            "
                          >
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-row v-if="!isEdit" justify="end" class="mt-4" no-gutters>
            <v-btn
              class="gradient-primary"
              dark
              depressed
              v-if="g_ppdb_riwayat.strata.length < 3"
              @click="addStrata"
              :disabled="isEdit"
            >
              {{ $t("ppdb.educational_history.strata_level_data.add") }}
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Perguruan Tinggi -->

      <!-- Start Pengalaman Kerja -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.educational_history.work_experience_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row no-gutters class="mt-5">
            <v-textarea
              v-model="g_ppdb_riwayat.pengalaman_kerja_cerita"
              :rules="!isManual() ? isValid($t('app.required')) : []"
              :readonly="isEdit"
              autocomplete="off"
              outlined
              dense
            >
              <template #label
                >{{
                  $t(
                    "ppdb.educational_history.work_experience_data.tell_me_about_yourself"
                  )
                }}<span v-if="!isManual()" class="red--text"
                  ><strong> *</strong></span
                >
              </template>
            </v-textarea>
          </v-row>

          <v-row v-if="!isEdit" class="mt-n5">
            <v-col cols="12">
              <v-checkbox
                v-model="g_ppdb_riwayat.freshgraduate"
                :label="
                  $t(
                    'ppdb.educational_history.work_experience_data.fresh_graduate'
                  )
                "
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="g_ppdb_riwayat.freshgraduate" no-gutters>
            <v-col cols="12">
              <v-card
                v-for="(d, i) in g_ppdb_riwayat.pengalaman_kerja"
                :key="i"
                outlined
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="12">
                        <v-row justify="end" class="mb-3" no-gutters>
                          <v-btn
                            color="pink"
                            icon
                            text
                            v-if="i !== 0"
                            @click="removePengalaman(i)"
                            :disabled="isEdit"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="
                            g_ppdb_riwayat.pengalaman_kerja[i].nama_perusahaan
                          "
                          :readonly="isEdit"
                          :rules="[
                            v => !!v || $t('ppdb.rules.company_name_rules')
                          ]"
                          name="company_name"
                          outlined
                          dense
                          required
                        >
                          <template #label
                            >{{
                              $t(
                                "ppdb.educational_history.work_experience_data.company_name"
                              )
                            }}
                            <span class="red--text"><strong> *</strong></span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          v-model="
                            g_ppdb_riwayat.pengalaman_kerja[i].posisi_terakhir
                          "
                          :readonly="isEdit"
                          :label="
                            $t(
                              'ppdb.educational_history.work_experience_data.last_position'
                            )
                          "
                          name="last_position"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-if="isEdit"
                          v-model="
                            g_ppdb_riwayat.pengalaman_kerja[i].tahun_masuk
                          "
                          append-icon="mdi-calendar"
                          name="year_of_entry"
                          outlined
                          dense
                          readonly
                        >
                          <template #label
                            >{{ $t("ppdb.educational_history.year_of_entry") }}
                            <span class="red--text"><strong> *</strong></span>
                          </template>
                        </v-text-field>
                        <v-menu
                          v-else
                          transition="scale-transition"
                          offset-y
                          v-model="tahunMasuk[i]"
                          :close-on-content-click="false"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="
                                g_ppdb_riwayat.pengalaman_kerja[i].tahun_masuk
                              "
                              :rules="[
                                v =>
                                  !!v || $t('ppdb.rules.year_of_entry_rules'),
                                v =>
                                  !g_ppdb_riwayat.pengalaman_kerja[i]
                                    .tahun_keluar ||
                                  validateTahunMasuk(
                                    v,
                                    g_ppdb_riwayat.pengalaman_kerja[i]
                                      .tahun_keluar
                                  ) ||
                                  $t('ppdb.rules.year_out_greater_than_rules')
                              ]"
                              :clearable="isEdit"
                              append-icon="mdi-calendar"
                              name="year_of_entry"
                              outlined
                              dense
                              required
                            >
                              <template #label
                                >{{
                                  $t("ppdb.educational_history.year_of_entry")
                                }}
                                <span class="red--text"
                                  ><strong> *</strong></span
                                >
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            type="month"
                            v-model="
                              g_ppdb_riwayat.pengalaman_kerja[i].tahun_masuk
                            "
                            :max="new Date().toISOString().substr(0, 10)"
                            @change="tahunMasuk[i] = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-if="isEdit"
                          v-model="
                            g_ppdb_riwayat.pengalaman_kerja[i].tahun_keluar
                          "
                          append-icon="mdi-calendar"
                          name="year_out"
                          outlined
                          dense
                          readonly
                        >
                          <template #label
                            >{{ $t("ppdb.educational_history.year_out") }}
                            <!-- <span class="red--text"
                                  ><strong> *</strong></span
                                > -->
                          </template>
                        </v-text-field>
                        <v-menu
                          v-else
                          v-model="tahunKeluar[i]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="
                                g_ppdb_riwayat.pengalaman_kerja[i].tahun_keluar
                              "
                              :disabled="
                                !(
                                  g_ppdb_riwayat.pengalaman_kerja[i].tahun_masuk
                                    .length == 7
                                )
                              "
                              :hint="
                                $t('ppdb.educational_history.year_out_hint')
                              "
                              :clearable="isEdit"
                              append-icon="mdi-calendar"
                              name="year_out"
                              outlined
                              dense
                              persistent-hint
                              required
                            >
                              <template #label
                                >{{ $t("ppdb.educational_history.year_out") }}
                                <!-- <span class="red--text"
                                  ><strong> *</strong></span
                                > -->
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            type="month"
                            :max="new Date().toISOString().substr(0, 10)"
                            v-model="
                              g_ppdb_riwayat.pengalaman_kerja[i].tahun_keluar
                            "
                            @change="tahunKeluar[i] = false"
                            :readonly="isEdit"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <ButtonUpload
                          v-if="!isEdit"
                          :label="
                            $t('ppdb.educational_history.upload_document')
                          "
                          :accept="acceptFile"
                          :rules="
                            isValid(
                              [
                                $t('ppdb.rules.upload_documents_rules'),
                                $t('ppdb.rules.upload_documents_type_rules')
                              ],
                              'upload'
                            )
                          "
                          :loading="dokumenPengalamanKerjaLoading"
                          class="mb-2"
                          multiple
                          depressed
                          @getDocument="
                            r => {
                              getPengalamanKerjaDocument(r, i);
                            }
                          "
                        />

                        <v-row no-gutters>
                          <v-col
                            v-for="(file, index) in g_ppdb_riwayat
                              .pengalaman_kerja[i].dokumen"
                            :key="index"
                            cols="12"
                            sm="4"
                            md="3"
                            class="text-center"
                          >
                            <v-chip
                              color="primary"
                              :close="!isEdit"
                              @click="openLink(file)"
                              @click:close="
                                fileDelete(
                                  g_ppdb_riwayat.pengalaman_kerja[i].dokumen,
                                  file
                                )
                              "
                            >
                              {{ file.replace(/^.*[\\\/]/, "") }}
                            </v-chip>
                            <v-card flat>
                              <v-img
                                style="max-width: 200px"
                                class="mt-2 mx-auto"
                                :src="
                                  g_ppdb_riwayat.pengalaman_kerja[i].dokumen[
                                    index
                                  ]
                                "
                                aspect-ratio="1.4"
                                @click="
                                  viewImage(
                                    g_ppdb_riwayat.pengalaman_kerja[i].dokumen[
                                      index
                                    ]
                                  )
                                "
                              >
                              </v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="mt-3 d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  class="gradient-primary"
                  dark
                  depressed
                  v-if="g_ppdb_riwayat.pengalaman_kerja.length < 10"
                  @click="addPengalaman"
                  :disabled="isEdit"
                >
                  {{ $t("ppdb.educational_history.strata_level_data.add") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="12">
              <ButtonUpload
                v-if="!isEdit"
                :label="$t('ppdb.educational_history.upload_certificate')"
                :accept="acceptFile"
                :rules="
                  isValid(
                    [
                      $t('ppdb.rules.upload_documents_rules'),
                      $t('ppdb.rules.upload_documents_type_rules')
                    ],
                    'upload'
                  )
                "
                :loading="dokumenNonFormalLoading"
                class="mb-2"
                multiple
                depressed
                @getDocument="
                  r => {
                    this.dokumenNonFormal = r;
                    this.uploadImage('upload', 'NON_FORMAL');
                  }
                "
              />
              <v-row no-gutters>
                <v-col
                  v-for="(file, index) in g_ppdb_riwayat.non_formal.dokumen"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="3"
                  class="text-center"
                >
                  <v-chip
                    color="primary"
                    :close="!isEdit"
                    @click="openLink(file)"
                    @click:close="
                      fileDelete(g_ppdb_riwayat.non_formal.dokumen, file)
                    "
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                  <v-card flat>
                    <v-img
                      style="max-width: 200px"
                      class="mt-2 mx-auto"
                      :src="g_ppdb_riwayat.non_formal.dokumen[index]"
                      aspect-ratio="1.4"
                      @click="
                        viewImage(g_ppdb_riwayat.non_formal.dokumen[index])
                      "
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Pengalaman Kerja -->
    </v-expansion-panels>

    <v-dialog :value="certificateModal">
      <v-card flat height="70%">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="certificateModal = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-img :src="certificateView" height="600" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { employeeUpdate } from "@/api/admin/master/employee";
import { deleteFile } from "@/api/admin/admin";
import ButtonUpload from "@/components/ButtonUpload";

export default {
  components: { ButtonUpload },
  props: {
    isValid: {
      type: Function,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    listStrata: ["Strata 1", "Strata 2", "Strata 3"],
    acceptFile: "image/png, image/jpeg, application/pdf",
    tahunMasuk: [false],
    tahunKeluar: [false],
    dokumenSd: [],
    dokumenSmp: [],
    dokumenSma: [],
    dokumenStrata: [],
    dokumenPengalamanKerja: [],
    dokumenNonFormal: [],
    dokumenSdLoading: false,
    dokumenSmpLoading: false,
    dokumenSmaLoading: false,
    dokumenStrataLoading: false,
    dokumenPengalamanKerjaLoading: false,
    dokumenNonFormalLoading: false,
    certificateView: null,
    certificateModal: false
  }),
  created() {
    if (!this.$route.query.id) {
      this.dokumenPengalamanKerja.push([]);
      this.dokumenStrata.push([]);
    }
  },
  computed: {
    ...mapGetters([
      "g_ppdb_riwayat",
      "g_ppdbguru",
      "g_master_data",
      "g_language",
      "g_darkmode"
    ]),
    ruleTahunMasukSD() {
      return [
        v => !!v || this.$i18n.t("ppdb.rules.year_of_entry_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SD.tahun_lulus ||
          parseInt(this.g_ppdb_riwayat.SD.tahun_lulus) > parseInt(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_greater_than_rules")
      ];
    },
    ruleTahunLulusSD() {
      return [
        v => !!v || this.$i18n.t("ppdb.rules.graduation_year_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SD.tahun_masuk ||
          parseInt(this.g_ppdb_riwayat.SD.tahun_masuk) < parseInt(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_less_than_rules")
      ];
    },
    ruleTahunMasukSMP() {
      return [
        v => !!v || this.$i18n.t("ppdb.rules.year_of_entry_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SMP.tahun_lulus ||
          parseInt(this.g_ppdb_riwayat.SMP.tahun_lulus) > parseInt(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_greater_than_rules")
      ];
    },
    ruleTahunLulusSMP() {
      return [
        v => !!v || this.$i18n.t("ppdb.rules.graduation_year_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SMP.tahun_masuk ||
          parseInt(this.g_ppdb_riwayat.SMP.tahun_masuk) < parseInt(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_less_than_rules")
      ];
    },
    ruleTahunMasukSMA() {
      return [
        v => !!v || this.$i18n.t("ppdb.rules.year_of_entry_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SMA.tahun_lulus ||
          parseInt(this.g_ppdb_riwayat.SMA.tahun_lulus) > parseInt(v) ||
          this.$i18n.t("ppdb.rules.year_of_entry_greater_than_rules")
      ];
    },
    ruleTahunLulusSMA() {
      return [
        // v => !!v || this.$i18n.t("ppdb.rules.graduation_year_rules"),
        v =>
          /[0-9]{4}/.test(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_valid_rules"),
        v =>
          !this.g_ppdb_riwayat.SMA.tahun_masuk ||
          parseInt(this.g_ppdb_riwayat.SMA.tahun_masuk) < parseInt(v) ||
          this.$i18n.t("ppdb.rules.graduation_year_less_than_rules")
      ];
    },
    rulesTahunMasukStrata() {
      var rulesData = [];
      for (var i in this.g_ppdb_riwayat.strata) {
        rulesData.push([
          v => !!v || this.$i18n.t("ppdb.rules.year_of_entry_rules"),
          v =>
            /[0-9]{4}/.test(v) ||
            this.$i18n.t("ppdb.rules.year_of_entry_valid_rules"),
          v => {
            if (
              this.g_ppdb_riwayat.strata[i].tahun_lulus &&
              this.g_ppdb_riwayat.strata[i].tahun_lulus != 0
            ) {
              return (
                parseInt(this.g_ppdb_riwayat.strata[i].tahun_lulus) >
                  parseInt(v) ||
                this.$i18n.t("ppdb.rules.year_of_entry_greater_than_rules")
              );
            } else return true;
          }
        ]);
      }
      return [...rulesData];
    },
    rulesTahunLulusStrata() {
      var rulesData = [];
      for (var i in this.g_ppdb_riwayat.strata) {
        rulesData.push([
          // v => !!v || this.$i18n.t("ppdb.rules.graduation_year_rules"),
          v =>
            /[0-9]{4}/.test(v) ||
            this.$i18n.t("ppdb.rules.graduation_year_valid_rules"),
          v => {
            if (
              this.g_ppdb_riwayat.strata[i].tahun_masuk &&
              this.g_ppdb_riwayat.strata[i].tahun_masuk != 0
            ) {
              return (
                parseInt(this.g_ppdb_riwayat.strata[i].tahun_masuk) <
                  parseInt(v) ||
                this.$i18n.t("ppdb.rules.graduation_year_less_than_rules")
              );
            } else return true;
          }
        ]);
      }
      return [...rulesData];
    },
    tahunLulusSD() {
      return this.g_ppdb_riwayat.SD.tahun_lulus;
    },
    tahunLulusSMP() {
      return this.g_ppdb_riwayat.SMP.tahun_lulus;
    },
    tahunLulusSMA() {
      return this.g_ppdb_riwayat.SMA.tahun_lulus;
    }
  },
  watch: {
    "g_ppdb_riwayat.freshgraduate"(newVal) {
      if (!newVal) {
        this.g_ppdb_riwayat.pengalaman_kerja = [
          {
            nama_perusahaan: "",
            posisi_terakhir: "",
            tahun_masuk: "",
            tahun_keluar: "",
            dokumen: []
          }
        ];
      }
    },
    "g_ppdb_riwayat.pengalaman_kerja"(newVal) {
      newVal.map(() => {
        this.dokumenPengalamanKerja.push([]);
      });
    },
    "g_ppdb_riwayat.strata"(newVal) {
      newVal.map(() => {
        this.dokumenStrata.push([]);
      });
    },
    tahunLulusSD() {
      this.$refs.tahunMasukSD.validate();
    },
    tahunLulusSMP() {
      this.$refs.tahunMasukSMP.validate();
    },
    tahunLulusSMA() {
      this.$refs.tahunMasukSMA.validate();
    }
  },
  methods: {
    getStrataDocument(r, i) {
      this.dokumenStrata[i] = r;
      this.uploadImage("upload", "STRATA", i);
    },
    getPengalamanKerjaDocument(r, i) {
      this.dokumenPengalamanKerja[i] = r;
      this.uploadImage("upload", "WORK_EXPERIENCE", i);
    },
    isManual() {
      const condition =
        this.$route.name == "AddTeacherManual" ||
        this.$route.name == "AddStaffManual";
      return condition;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async fileDelete(array, item) {
      let split = item.split("/");
      split.splice(0, 4);
      array.splice(array.indexOf(item), 1);
      try {
        const response = await deleteFile(split.join("/"), "REGISTRATION");
        if (response.data.code) {
          // update data if only on edit page
          if (this.$route.query.id) {
            const postEdit = {
              employee: this.g_ppdbguru.employee,
              guruOrStaff: this.g_ppdbguru.guruOrStaff,
              auto_accept: this.g_ppdbguru.auto_accept,
              id_sekolah: this.g_ppdbguru.id_sekolah,
              guru: {
                guru: this.g_ppdbguru.guru,
                riwayat: this.g_ppdbguru.riwayat
              }
            };
            employeeUpdate(postEdit);
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.log("fileDelete()", error);
      }
    },
    openLink: url => window.open(url, "_blank"),
    deleteImage(type, file, idx) {
      this.$store
        .dispatch("deleteImage", {
          file: file,
          idx: idx ? idx : 0,
          type: type
        })
        .then(() => {
          this.setLoadingUpload();
        })
        .catch(() => {
          this.setLoadingUpload();
        });
    },
    viewImage(img) {
      this.certificateView = img;
      this.certificateModal = true;
    },
    uploadImage(action, type, idx) {
      if (action == "upload") {
        let file = null;

        switch (type) {
          case "SD":
            this.dokumenSdLoading = true;
            file = this.dokumenSd;
            break;
          case "SMP":
            this.dokumenSmpLoading = true;
            file = this.dokumenSmp;
            break;
          case "SMA":
            this.dokumenSmaLoading = true;
            file = this.dokumenSma;
            break;
          case "STRATA":
            this.dokumenStrataLoading = true;
            if (this.dokumenStrata.length > 0) file = this.dokumenStrata[idx];
            break;
          case "WORK_EXPERIENCE":
            this.dokumenPengalamanKerjaLoading = true;
            if (this.dokumenPengalamanKerja.length > 0)
              file = this.dokumenPengalamanKerja[idx];
            break;
          default:
            this.dokumenNonFormalLoading = true;
            file = this.dokumenNonFormal;
            break;
        }

        this.$store
          .dispatch("uploadImage", {
            file: file,
            idx: idx ? idx : 0,
            type: type
          })
          .then(() => {
            this.setLoadingUpload();
          })
          .catch(() => {
            this.setLoadingUpload();
          });
      }
    },
    setLoadingUpload() {
      this.dokumenSdLoading = false;
      this.dokumenSmpLoading = false;
      this.dokumenSmaLoading = false;
      this.dokumenStrataLoading = false;
      this.dokumenPengalamanKerjaLoading = false;
      this.dokumenNonFormalLoading = false;
    },
    generateYears() {
      let currentYear = new Date().getFullYear();
      let years = [];
      let startYear = 1950;
      while (startYear <= currentYear) {
        let year = startYear++;
        years.push({ name: `${year}`, id: year });
      }
      return years.reverse();
    },
    removeStrata(index) {
      this.g_ppdb_riwayat.strata.splice(index, 1);
    },
    addStrata() {
      this.dokumenStrata.push([]);
      this.g_ppdb_riwayat.strata.push({
        jenjang: "",
        asal_institusi: "",
        akreditas: "",
        tahun_masuk: "",
        nomor_ijazah: "",
        nilai_ipk: "",
        tahun_lulus: "",
        dokumen: []
      });
    },
    addPengalaman() {
      this.dokumenPengalamanKerja.push([]);
      this.g_ppdb_riwayat.pengalaman_kerja.push({
        nama_perusahaan: "",
        posisi_terakhir: "",
        tahun_masuk: "",
        tahun_keluar: "",
        dokumen: []
      });
      this.tahunMasuk.push(false);
      this.tahunKeluar.push(false);
    },
    removePengalaman(index) {
      this.g_ppdb_riwayat.pengalaman_kerja.splice(index, 1);
    },
    validateTahunMasuk(thnMasuk, thnKeluar) {
      var keluar = new Date(thnKeluar);
      var masuk = new Date(thnMasuk);
      return keluar > masuk;
    },
    validateTahunKeluar(thnKeluar, thnMasuk) {
      var masuk = new Date(thnMasuk);
      var keluar = new Date(thnKeluar);
      return masuk < keluar;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
