<template>
  <v-container>
    <v-expansion-panels v-model="g_ppdb_siswa.open_panel" multiple hover>
      <!-- Data Diri -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.student.personal_data_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content
          id="datadiri"
          :class="!g_darkmode && 'grey lighten-5'"
        >
          <v-row class="mt-5">
            <v-col cols="12" sm="4">
              <CropImage
                :value="g_ppdb_siswa.data_diri.foto"
                :croped-image="setCropedImage"
                :is-edit="isEdit"
                :viewMode="3"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.nama_lengkap"
                :readonly="isEdit"
                :rules="isValid($t('ppdb.rules.fullname_rules'))"
                name="fullname"
                outlined
                dense
                required
                ><template #label>
                  {{ $t("ppdb.student.personal_data.fullname") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.nama_panggilan"
                :readonly="isEdit"
                v-bind:label="$t('ppdb.student.personal_data.nickname')"
                name="nickname"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.tempat_lahir"
                :readonly="isEdit"
                :rules="isValid($t('ppdb.rules.pob_rules'))"
                name="pob"
                outlined
                dense
                required
                ><template #label>
                  {{ $t("ppdb.personal.pob") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
              <v-menu
                ref="tgllahir"
                v-model="tglLahir"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="g_ppdb_siswa.data_diri.tanggal_lahir"
                    :disabled="isEdit"
                    :rules="isValid($t('ppdb.rules.dob_rules'))"
                    name="dob"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    readonly
                    required
                    ><template #label>
                      {{ $t("ppdb.personal.dob") }}
                      <span class="red--text"><strong>* </strong></span>
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="g_ppdb_siswa.data_diri.tanggal_lahir"
                  :active-picker.sync="activePicker"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="
                    $refs.tgllahir.save(g_ppdb_siswa.data_diri.tanggal_lahir)
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="g_ppdb_siswa.data_diri.jenis_kelamin"
                :readonly="isEdit"
                :rules="isValid($t('ppdb.rules.gender_rules'))"
                :items="g_master_data.genders"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                name="gender"
                outlined
                dense
                required
                ><template #label>
                  {{ $t("ppdb.student.personal_data.gender") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-select
              >
              <v-select
                v-model="g_ppdb_siswa.data_diri.agama"
                :readonly="isEdit"
                :rules="isValid($t('ppdb.rules.religion_rules'))"
                :items="g_master_data.religions"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                name="religion"
                outlined
                dense
                required
                ><template #label>
                  {{ $t("ppdb.personal.religion") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-select
              >
              <v-select
                v-model="g_ppdb_siswa.data_diri.kewarganegaraan"
                :readonly="isEdit"
                :label="$t('ppdb.personal.nationality')"
                :items="g_master_data.nationality"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                name="nationality"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.bahasa"
                :label="$t('ppdb.student.personal_data.language')"
                :readonly="isEdit"
                name="language"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.no_hp"
                :readonly="isEdit"
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.phone_rules'),
                      b: $t('ppdb.rules.phone_valid_rules')
                    },
                    'phonenumberrequired'
                  )
                "
                :label="$t('ppdb.personal.phone_number')"
                name="phonenumber"
                outlined
                dense
                maxlength="14"
                @keypress="isNumberOnly($event)"
                ><template #label>
                  {{ $t("ppdb.personal.phone_number") }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.nik"
                :readonly="isEdit"
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.nik_rules'),
                      b: $t('ppdb.rules.nik_not_valid_length')
                    },
                    'niknumberrequired'
                  )
                "
                name="nik"
                outlined
                dense
                maxlength="16"
                @keypress="isNumberOnly($event)"
              >
                <template #label>
                  {{ $t("ppdb.student.personal_data.nik") }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.email"
                :readonly="isEdit"
                :label="$t('ppdb.personal.email')"
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.email_rules'),
                      b: $t('ppdb.rules.email_valid_rules')
                    },
                    'email'
                  )
                "
                name="email"
                outlined
                dense
                ><template #label>
                  {{ $t("ppdb.personal.email") }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="g_ppdb_siswa.data_diri.no_kk"
                :readonly="isEdit"
                :label="$t('ppdb.student.personal_data.family_card_number')"
                :rules="[
                  v =>
                    !v ||
                    (v.length === 16 && /[0-9]$/.test(v)) ||
                    $t('ppdb.rules.family_card_number_not_valid')
                ]"
                name="family_card_number"
                maxlength="16"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="$route.query.id ? '6' : '12'">
              <v-textarea
                v-model="g_ppdb_siswa.data_diri.informasi_lainnya"
                :label="$t('ppdb.personal.others_information')"
                :readonly="isEdit"
                row-height="15"
                autocomplete="off"
                auto-grow
                rows="1"
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col v-if="$route.query.id ? true : false" cols="12" sm="6">
              <v-text-field
                v-if="!isApplicant"
                v-model="g_ppdbsiswa.student.nis"
                :readonly="isEdit"
                :rules="[
                  v => /[0-9]$/.test(v) || $t('ppdb.rules.nis_valid_rules')
                ]"
                name="nis"
                outlined
                dense
                required
                ><template #label>
                  {{ $t("app.nis") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-if="isEdit"
                v-model="g_ppdb_siswa.data_diri.username"
                label="Username"
                name="username"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Diri -->
      <!-- Jurusan Siswa -->
      <v-expansion-panel v-if="!isEditStudent()" class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{ $t("ppdb.student.major_sub") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content
          id="major"
          :class="!g_darkmode && 'grey lighten-5'"
        >
          <v-row class="mt-4">
            <v-col cols="12" sm="12">
              <div class="font-weight-bold">
                {{ $t("ppdb.student.choose_major") }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="g_ppdb_siswa.jurusan_siswa.major_primary"
                :readonly="isEdit"
                :rules="
                  isMandatoryMajor
                    ? isValid($t('ppdb.rules.major_primary_rules'))
                    : []
                "
                :items="listMajor"
                :loading="loadingGetMajor"
                item-text="name"
                return-object
                outlined
                dense
                required
              >
                <template #label>
                  {{ $t("ppdb.student.preferred_major") }}
                  <span v-if="isMandatoryMajor" class="red--text">
                    <strong>* </strong>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="g_ppdb_siswa.jurusan_siswa.major_secondary"
                :readonly="isEdit"
                :items="listMajor"
                :label="$t('ppdb.student.optional_major')"
                :item-disabled="disableOptionalMajor"
                :disabled="g_ppdb_siswa.jurusan_siswa.major_primary === null"
                :loading="loadingGetMajor"
                item-text="name"
                return-object
                outlined
                dense
                hide-details
                multiple
                open-on-clear
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0"> {{ item.name }}</span>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{
                      g_ppdb_siswa.jurusan_siswa.major_secondary.length - 1
                    }}
                    others)
                  </span>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    group
                    v-bind="attrs"
                    v-on="on"
                    v-slot:default="{ active }"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ item.name }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        :key="item.major_id"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" cols="12">
              <template
                v-if="g_ppdb_siswa.jurusan_siswa.major_primary !== null"
              >
                <v-chip
                  :disabled="isEdit"
                  color="primary"
                  class="ma-2"
                  close
                  @click:close="closePrimaryMajor()"
                >
                  {{ g_ppdb_siswa.jurusan_siswa.major_primary.name }}
                </v-chip>
              </template>
            </v-col>
            <v-col sm="6" cols="12">
              <template>
                <v-chip
                  v-for="(item, i) in g_ppdb_siswa.jurusan_siswa
                    .major_secondary"
                  :key="i"
                  :disabled="isEdit"
                  color="primary"
                  class="ma-2"
                  close
                  @click:close="closeOptionalMajor()"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-alert text dense color="blue-grey darken-1" border="left">
                <div class="subtitle-1">
                  {{ $t("ppdb.student.notes_major.notes") }}
                </div>
                <ol class="body-2">
                  <li class="font-italic">
                    {{ $t("ppdb.student.notes_major.first") }}
                  </li>
                  <li class="font-italic">
                    {{ $t("ppdb.student.notes_major.two") }}
                  </li>
                </ol>
              </v-alert>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Jurusan Siswa -->
      <!-- Alamat Siswa -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.student.address_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content
          id="address"
          :class="!g_darkmode && 'grey lighten-5'"
        >
          <v-row class="mt-4">
            <v-col cols="12">
              <v-row no-gutters class="mt-2">
                <v-text-field
                  v-model="g_ppdb_siswa.alamat_siswa.alamat"
                  :readonly="isEdit"
                  :rules="isValid($t('ppdb.rules.address_rules'))"
                  name="address"
                  id="address"
                  outlined
                  dense
                  required
                  ><template #label>
                    {{ $t("ppdb.address.address") }}
                    <span class="red--text"><strong>* </strong></span>
                  </template></v-text-field
                >
                <v-btn
                  v-if="!isEdit"
                  tile
                  depressed
                  class="primary ml-4 px-2 rounded"
                  style="margin-top: 2px"
                  @click="openMapSiswa = !openMapSiswa"
                >
                  {{ openMapSiswa ? $t("app.close_map") : $t("app.open_map") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="openMapSiswa" class="mb-4">
              <MapBox :open-map="openMapSiswa" :set-data="setAlamatSiswa" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.provinsi"
                :readonly="isEdit"
                :label="$t('ppdb.address.province')"
                name="province"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.kota"
                :readonly="isEdit"
                :label="$t('ppdb.address.city')"
                name="city"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.kecamatan"
                :readonly="isEdit"
                :label="$t('ppdb.address.sub_district')"
                name="districts"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.kelurahan"
                :readonly="isEdit"
                :label="$t('ppdb.address.village')"
                name="sub-district"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.rt"
                :readonly="isEdit"
                :label="$t('ppdb.address.rt')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                name="neighborhood_association"
                outlined
                dense
                maxlength="3"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.rw"
                :readonly="isEdit"
                :label="$t('ppdb.address.rw')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                name="citizens_association"
                outlined
                dense
                maxlength="3"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.kode_pos"
                :readonly="isEdit"
                :label="$t('ppdb.address.postal_code')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                name="postal_code"
                outlined
                dense
                maxlength="5"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.telepon_rumah"
                :readonly="isEdit"
                :label="$t('ppdb.address.telephone')"
                :rules="isValid($t('ppdb.rules.phone_valid_rules'), 'number')"
                name="house_phone"
                maxlength="14"
                dense
                outlined
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="g_ppdb_siswa.alamat_siswa.tinggal_bersama"
                :readonly="isEdit"
                :label="$t('ppdb.address.live_with')"
                :items="g_master_data.live_with"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                name="live_with"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Alamat Siswa -->

      <!-- Data Keluarga -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.student.family_data_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.anak_ke"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="isEdit"
                :label="$t('ppdb.address.child_to')"
                name="child_to"
                outlined
                dense
                maxlength="2"
                hide-details
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_siswa.alamat_siswa.jumlah_saudara"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="isEdit"
                :label="$t('ppdb.address.number_of_siblings')"
                name="number_of_siblings"
                outlined
                dense
                maxlength="2"
                hide-details
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card
            v-for="(d, i) in g_ppdb_siswa.data_keluarga"
            :key="i"
            class="mt-5"
            outlined
          >
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12">
                    <div class="font-weight-bold my-3">
                      {{ $t("ppdb.student.family_data.child_to") }} -
                      {{ i + 1 }}
                      <v-btn
                        v-if="i !== 0"
                        text
                        icon
                        color="pink"
                        class="float-right mt-n3"
                        @click="removeFamilyData(i)"
                        :disabled="isEdit"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <v-text-field
                      v-model="d.nama"
                      :readonly="isEdit"
                      :label="$t('ppdb.student.family_data.child_name')"
                      name="child_name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="d.semester"
                      :readonly="isEdit"
                      :label="$t('ppdb.student.family_data.semester')"
                      name="semester"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="d.sekolah"
                      :readonly="isEdit"
                      :label="$t('ppdb.student.family_data.school')"
                      name="school_name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-row class="text-right mt-3">
            <v-col cols="12">
              <v-btn
                v-if="g_ppdb_siswa.data_keluarga.length < 5"
                :disabled="isEdit"
                class="primary"
                depressed
                @click="addFamilyData"
                >{{ $t("ppdb.student.family_data.add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Keluarga -->

      <!-- Data Kesehatan -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.student.health_data_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-3">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.data_kesehatan.berat"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :label="$t('ppdb.student.health_data.weight')"
                :readonly="isEdit"
                name="weight"
                outlined
                dense
                @keypress="isNumberFloat($event)"
              ></v-text-field>
              <v-select
                v-model="g_ppdb_siswa.data_kesehatan.golongan_darah"
                :label="$t('ppdb.student.health_data.blood')"
                :readonly="isEdit"
                :items="g_master_data.bloods"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                name="blood"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_siswa.data_kesehatan.jarak"
                :readonly="isEdit"
                :label="$t('ppdb.student.health_data.distance')"
                name="distance"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.data_kesehatan.tinggi"
                :readonly="isEdit"
                :label="$t('ppdb.student.health_data.height')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                name="height"
                maxlength="4"
                outlined
                dense
                @keypress="isNumberFloat($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_siswa.data_kesehatan.riwayat"
                :readonly="isEdit"
                :label="$t('ppdb.student.health_data.medical_history')"
                name="medical_history"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_siswa.data_kesehatan.catatan"
                :label="$t('ppdb.student.health_data.health_record')"
                :readonly="isEdit"
                name="health_record"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Kesehatan -->

      <!-- Pendidikan Sebelumnya -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.student.previous_education_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content
          id="beforeeducation"
          :class="!g_darkmode && 'grey lighten-5'"
        >
          <v-row class="mt-4">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.asal"
                :label="$t('ppdb.student.previous_education.school_origin')"
                :readonly="isEdit"
                :rules="[v => !!v || $t('app.required')]"
                name="school_origin"
                outlined
                dense
                ><template #label>
                  {{ $t("ppdb.student.previous_education.school_origin") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.no_pokok_sekolah"
                :readonly="isEdit"
                :label="
                  $t(
                    'ppdb.student.previous_education.national_school_principal'
                  )
                "
                name="national_school_principal"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.alamat_sekolah"
                :label="$t('ppdb.student.previous_education.school_address')"
                :readonly="isEdit"
                name="school_address"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                id="nisn"
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.nisn"
                :label="
                  $t('ppdb.student.previous_education.national_school_parent')
                "
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.nis_rules'),
                      b: $t('ppdb.rules.nis_valid_rules')
                    },
                    'nisnnumberrequired'
                  )
                "
                :readonly="isEdit"
                maxlength="10"
                name="national_school_number"
                @keypress="isNumberOnly($event)"
                outlined
                dense
                ><template #label>
                  {{
                    $t("ppdb.student.previous_education.national_school_parent")
                  }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.tahun_lulus"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :label="$t('ppdb.student.previous_education.year_graduated')"
                :readonly="isEdit"
                ame="year_graduate"
                maxlength="4"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="g_ppdb_siswa.pendidikan_sebelumnya.no_ijazah"
                :label="$t('ppdb.student.previous_education.diploma_number')"
                :readonly="isEdit"
                name="diploma_number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <ButtonUpload
                v-if="!isEdit"
                :label="$t('ppdb.educational_history.upload_document')"
                :rules="
                  isValid(
                    [
                      $t('ppdb.rules.upload_documents_rules'),
                      $t('ppdb.rules.upload_documents_type_rules')
                    ],
                    'upload'
                  )
                "
                :loading="dokumenLoading"
                class="mb-2"
                accept="image/png, image/jpeg, application/pdf"
                multiple
                depressed
                @getDocument="
                  r => {
                    this.dokumenPendidikan = r;
                    this.uploadFile();
                  }
                "
              />
              <v-row no-gutters>
                <v-col
                  v-for="(file, index) in g_ppdb_siswa.pendidikan_sebelumnya
                    .dokumen"
                  :key="index"
                  cols="12"
                  sm="4"
                  md="3"
                  class="text-center"
                >
                  <v-chip
                    color="primary"
                    :close="!isEdit"
                    @click="openLink(file)"
                    @click:close="
                      fileDelete(
                        g_ppdb_siswa.pendidikan_sebelumnya.dokumen,
                        file
                      )
                    "
                  >
                    {{ file.replace(/^.*[\\\/]/, "") }}
                  </v-chip>
                  <v-card flat>
                    <v-img
                      style="max-width: 200px"
                      class="mt-2 mx-auto"
                      :src="g_ppdb_siswa.pendidikan_sebelumnya.dokumen[index]"
                      aspect-ratio="1.4"
                      @click="
                        viewImage(
                          g_ppdb_siswa.pendidikan_sebelumnya.dokumen[index]
                        )
                      "
                    >
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Pendidikan Sebelumnya -->
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CropImage from "@/components/ImageCrop";
import MapBox from "@/components/MapBox";
import { isNumberOnly, isNumberFloat } from "@/utils/validate";
import { deleteFile } from "@/api/admin/admin";
import { studentUpdate } from "@/api/admin/master/student";
import { get_major_ppdb } from "@/api/homepage/ppdb.js";
import ButtonUpload from "@/components/ButtonUpload";
import { createMajorList, mandatoryMajor } from "@/utils/major";

export default {
  components: { CropImage, MapBox, ButtonUpload },
  props: {
    isValid: Function,
    isEdit: {
      type: Boolean,
      default: false
    },
    isApplicant: String || undefined
  },
  computed: {
    ...mapGetters([
      "g_ppdb_siswa",
      "g_master_data",
      "g_language",
      "g_ppdbsiswa",
      "g_darkmode"
    ]),
    isMandatoryMajor() {
      return mandatoryMajor();
    }
  },
  data: () => ({
    boyPhoto: require("@/assets/svg/boy.svg"),
    girlPhoto: require("@/assets/svg/girl.svg"),
    tglLahir: false,
    checkNISN: true,
    openMapSiswa: false,
    dokumenLoading: false,
    dokumenPendidikan: [],
    listMajor: [],
    loadingGetMajor: false,
    activePicker: null
  }),
  watch: {
    tglLahir() {
      setTimeout(() => (this.activePicker = "YEAR"));
    },
    openMapSiswa(newVal) {
      if (!newVal) this.$store.commit("PPDB_RESET_ALAMAT_SISWA");
    }
  },
  created() {
    this.getDataMajor();
  },
  methods: {
    isEditStudent() {
      if (this.$route.name === "StudentManual") {
        const route = this.$route.query;
        return route.id != null && route.year != null;
      }
      return false;
    },
    getDataMajor() {
      this.loadingGetMajor = true;
      get_major_ppdb()
        .then(res => {
          this.listMajor = createMajorList(res.data.data);
          this.loadingGetMajor = false;
        })
        .catch(() => (this.loadingGetMajor = false));
    },
    closePrimaryMajor() {
      const data = {
        major_id: 0,
        expertise_id: 0,
        reference: []
      };

      this.g_ppdb_siswa.jurusan_siswa.major_primary = data;
    },
    closeOptionalMajor(item) {
      let position = this.g_ppdb_siswa.jurusan_siswa.major_secondary.indexOf(
        item
      );
      this.g_ppdb_siswa.jurusan_siswa.major_secondary.splice(position, 1);
      this.g_ppdb_siswa.jurusan_siswa.major_secondary = [
        ...this.g_ppdb_siswa.jurusan_siswa.major_secondary
      ];
    },
    disableOptionalMajor(item) {
      let optionalMajor = this.g_ppdb_siswa.jurusan_siswa.major_secondary;

      return optionalMajor.length >= 2
        ? item.name != optionalMajor[0].name &&
            item.name != optionalMajor[1].name
        : item.name == this.g_ppdb_siswa.jurusan_siswa.major_primary.name;
    },
    async fileDelete(array, item) {
      let split = item.split("/");
      split.splice(0, 4);
      array.splice(array.indexOf(item), 1);
      try {
        const response = await deleteFile(split.join("/"), "REGISTRATION");
        if (response.data.code) {
          // update data if only on edit page
          if (this.$route.query.id) {
            const postEdit = {
              student: this.g_ppdbsiswa.student,
              siswa: {
                data_diri: this.g_ppdbsiswa.siswa.data_diri,
                alamat_siswa: this.g_ppdbsiswa.siswa.alamat_siswa,
                data_keluarga: this.g_ppdbsiswa.siswa.data_keluarga,
                data_kesehatan: this.g_ppdbsiswa.siswa.data_kesehatan,
                pendidikan_sebelumnya: this.g_ppdbsiswa.siswa
                  .pendidikan_sebelumnya,
                orang_tua: {
                  data_ayah: this.g_ppdbsiswa.orang_tua.data_ayah,
                  alamat_ayah: this.g_ppdbsiswa.orang_tua.alamat_ayah,
                  data_ibu: this.g_ppdbsiswa.orang_tua.data_ibu,
                  alamat_ibu: this.g_ppdbsiswa.orang_tua.alamat_ibu,
                  wali: {
                    isOrtu: this.g_ppdbsiswa.wali.isOrtu,
                    isFatherOrMother: this.g_ppdbsiswa.wali.isFatherOrMother,
                    data_wali: this.g_ppdbsiswa.wali.data_wali,
                    alamat_wali: this.g_ppdbsiswa.wali.alamat_wali
                  }
                }
              }
            };

            studentUpdate(postEdit);
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.log("fileDelete()", error);
      }
    },
    uploadFile() {
      if (this.dokumenPendidikan.length > 0) {
        this.dokumenLoading = true;
        this.$store
          .dispatch("uploadImageStudent", this.dokumenPendidikan)
          .then(() => {
            this.dokumenLoading = false;
          })
          .catch(() => {
            this.dokumenLoading = false;
          });
      }
    },
    deleteImage() {
      const document = this.g_ppdb_siswa.pendidikan_sebelumnya.dokumen;
      if (document.length > 0) {
        this.$store.dispatch(
          "deleteImageStudent",
          this.g_ppdb_siswa.pendidikan_sebelumnya.dokumen
        );
      }
    },
    openLink: url => window.open(url, "_blank"),
    isNumberOnly: evt => isNumberOnly(evt),
    isNumberFloat: evt => isNumberFloat(evt),
    addFamilyData() {
      this.g_ppdb_siswa.data_keluarga.push({
        nama: "",
        semester: "",
        sekolah: ""
      });
    },
    removeFamilyData(index) {
      this.g_ppdb_siswa.data_keluarga.splice(index, 1);
    },
    setCropedImage(value) {
      this.g_ppdb_siswa.data_diri.foto = value;
    },
    setAlamatSiswa(data) {
      this.$store.commit("PPDB_SET_ALAMAT_SISWA", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
.required label::after {
  content: "*";
}
</style>
