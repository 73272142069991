<template>
  <v-container>
    <v-expansion-panels hover v-model="g_ppdb_guru.open_panel" multiple>
      <!-- Start Data Guru -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">
          {{
            g_ppdbguru.guruOrStaff == "TEACHER"
              ? $t("ppdb.teacher.teacher_data_sub")
              : $t("ppdb.teacher.staff_data_sub")
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-5" justify="center">
            <v-col cols="12" sm="4">
              <v-img
                v-if="
                  (g_ppdb_guru.data_guru.foto &&
                    isUrl(g_ppdb_guru.data_guru.foto)) ||
                    isEdit
                "
                :src="g_ppdb_guru.data_guru.foto"
              >
                <v-row v-if="!isEdit" no-gutters justify="end">
                  <v-btn
                    fab
                    x-small
                    color="error"
                    @click="g_ppdb_guru.data_guru.foto = null"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-row>
              </v-img>
              <CropImage
                v-else
                :croped-image="setCropedImage"
                :is-edit="isEdit"
                :viewMode="3"
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-row>
                <v-col cols="12" sm="12" class="py-0 mt-2">
                  <v-text-field
                    v-model="g_ppdb_guru.data_guru.nama"
                    :rules="[v => !!v || $t('ppdb.rules.fullname_rules')]"
                    :readonly="isEdit"
                    name="fullname"
                    outlined
                    dense
                    required
                  >
                    <template #label
                      >{{ $t("ppdb.teacher.teacher_data.fullname") }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="g_ppdb_guru.data_guru.tempat_lahir"
                    :rules="[v => !!v || $t('ppdb.rules.pob_rules')]"
                    :readonly="isEdit"
                    name="pob"
                    required
                    outlined
                    dense
                  >
                    <template #label
                      >{{ $t("ppdb.personal.pob")
                      }}<span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                  <v-select
                    v-model="g_ppdb_guru.data_guru.jenis_kelamin"
                    :rules="[v => !!v || $t('ppdb.rules.gender_rules')]"
                    :readonly="isEdit"
                    :items="g_master_data.genders"
                    :item-text="
                      g_language == 'id' ? 'description_ina' : 'description_eng'
                    "
                    item-value="value"
                    name="gender"
                    required
                    outlined
                    dense
                  >
                    <template #label
                      >{{ $t("ppdb.teacher.teacher_data.gender")
                      }}<span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="g_ppdb_guru.data_guru.telepon"
                    :readonly="isEdit"
                    :label="$t('ppdb.personal.phone_number')"
                    :rules="
                      isValid($t('ppdb.rules.phone_valid_rules'), 'phonenumber')
                    "
                    name="phone"
                    outlined
                    dense
                    maxlength="14"
                    @keypress="isNumberOnly($event)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-menu
                    ref="tgllahir"
                    v-model="tglLahir"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="g_ppdb_guru.data_guru.tanggal_lahir"
                        :rules="[v => !!v || $t('ppdb.rules.dob_rules')]"
                        :disabled="isEdit"
                        append-icon="mdi-calendar"
                        name="dob"
                        readonly
                        required
                        outlined
                        dense
                      >
                        <template #label
                          >{{ $t("ppdb.personal.dob")
                          }}<span class="red--text"><strong> *</strong></span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="g_ppdb_guru.data_guru.tanggal_lahir"
                      :active-picker.sync="activePicker"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="
                        $refs.tgllahir.save(g_ppdb_guru.data_guru.tanggal_lahir)
                      "
                      @input="tglLahirFlag = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    v-model="g_ppdb_guru.data_guru.agama"
                    :items="g_master_data.religions"
                    :item-text="
                      g_language == 'id' ? 'description_ina' : 'description_eng'
                    "
                    item-value="value"
                    :rules="[v => !!v || $t('ppdb.rules.religion_rules')]"
                    :readonly="isEdit"
                    name="religions"
                    required
                    outlined
                    dense
                  >
                    <template #label
                      >{{ $t("ppdb.personal.religion")
                      }}<span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-select>
                  <v-text-field
                    v-if="isEdit"
                    v-model="g_ppdb_guru.data_guru.username"
                    label="Username"
                    name="username"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- End Data Guru -->

      <!-- Start Alamat Guru -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          g_ppdbguru.guruOrStaff == "TEACHER"
            ? $t("ppdb.teacher.address_sub")
            : $t("ppdb.teacher.address_staff")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-3" justify="center">
            <v-col cols="12">
              <v-row no-gutters class="mt-2 d-flex">
                <v-text-field
                  v-model="g_ppdb_guru.alamat_guru.alamat"
                  :rules="[
                    v => !!v || $t('ppdb.rules.address_rules'),
                    v => v.length > 10 || $t('ppdb.rules.address_valid_rules')
                  ]"
                  :readonly="isEdit"
                  name="address"
                  required
                  outlined
                  dense
                >
                  <template #label
                    >{{ $t("ppdb.address.address")
                    }}<span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
                <v-btn
                  v-if="!isEdit"
                  :disabled="isEdit"
                  class="primary ml-4 px-2 rounded"
                  style="margin-top: 2px"
                  tile
                  depressed
                  @click="openMapGuru = !openMapGuru"
                >
                  {{ openMapGuru ? $t("app.close_map") : $t("app.open_map") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="openMapGuru" class="mb-6">
              <MapBox :open-map="openMapGuru" :set-data="setAlamatGuru" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.provinsi"
                :readonly="isEdit"
                :label="$t('ppdb.address.province')"
                name="province"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.kode_pos"
                :rules="[
                  v => !v || /[0-9]$/.test(v) || $t('ppdb.rules.number_rules')
                ]"
                :label="$t('ppdb.address.postal_code')"
                :readonly="isEdit"
                name="postalcode"
                outlined
                dense
                maxlength="6"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.telepon_rumah"
                :rules="[
                  v => !v || /[0-9]$/.test(v) || $t('ppdb.rules.number_rules')
                ]"
                :readonly="isEdit"
                :label="$t('ppdb.address.telephone')"
                maxlength="14"
                name="telephone"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.kota"
                :readonly="isEdit"
                :label="$t('ppdb.address.city')"
                name="city"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.negara"
                :readonly="isEdit"
                :label="$t('ppdb.address.country')"
                name="country"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_guru.alamat_guru.email"
                :readonly="isEdit"
                :label="$t('ppdb.personal.email')"
                :rules="[
                  v =>
                    !v ||
                    /.+@.+\..+/.test(v) ||
                    $t('ppdb.rules.email_valid_rules')
                ]"
                name="email"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- End ALamat Guru -->
  </v-container>
</template>

<script>
import CropImage from "@/components/ImageCrop";
import { mapGetters } from "vuex";
import MapBox from "@/components/MapBox";
import { isNumberOnly } from "@/utils/validate";

export default {
  components: { MapBox, CropImage },
  props: {
    isValid: Function,
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tglLahir: false,
    openMapGuru: false,
    activePicker: null
  }),
  computed: mapGetters([
    "g_ppdb_guru",
    "g_master_data",
    "g_language",
    "g_ppdbguru",
    "g_darkmode"
  ]),
  watch: {
    tglLahir() {
      setTimeout(() => (this.activePicker = "YEAR"));
    },
    openMapGuru(newVal) {
      if (!newVal) this.$store.commit("PPDB_RESET_ALAMAT_GURU");
    }
  },
  methods: {
    isNumberOnly: evt => isNumberOnly(evt),
    isUrl(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    setCropedImage(value) {
      this.g_ppdb_guru.data_guru.foto = value;
    },
    setAlamatGuru(data) {
      this.$store.commit("PPDB_SET_ALAMAT_GURU", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
