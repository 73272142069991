<template>
  <v-container>
    <v-expansion-panels hover v-model="g_ppdb_wali.open_panel" multiple>
      <v-row class="text-center">
        <v-col cols="12">
          <div>{{ $t("ppdb.guardian.question1") }}</div>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <span class="mt-n2 font-weight-light">{{ $t("app.no") }}</span>
            <v-switch
              v-model="g_ppdb_wali.isOrtu"
              :label="$t('app.yes')"
              :readonly="isEdit"
              class="d-inline ml-3"
              @change="setWaliIsOrtu()"
            ></v-switch>
          </v-row>
          <v-row v-if="g_ppdb_wali.isOrtu" align="center" justify="center">
            <v-col cols="12">
              <div>{{ $t("ppdb.guardian.question2") }}</div>
            </v-col>
            <v-col cols="12">
              <v-radio-group
                v-model="g_ppdb_wali.isFatherOrMother"
                :readonly="isEdit"
                @change="getDataWali()"
              >
                <v-radio
                  :label="$t('ppdb.guardian.father')"
                  value="ayah"
                ></v-radio>
                <v-radio
                  :label="$t('ppdb.guardian.mother')"
                  value="ibu"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Data Wali Murid -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.guardian.guardian_data")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row>
            <v-col cols="12" class="mt-5">
              <v-text-field
                v-model="g_ppdb_wali.data_wali.nama"
                :rules="isValid($t('ppdb.rules.guardian_name_rules'))"
                :readonly="isEdit"
                name="guardian_name"
                required
                outlined
                dense
                ><template #label>
                  {{ $t("ppdb.guardian.personal.name") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_wali.data_wali.tempat_lahir"
                :label="$t('ppdb.personal.pob')"
                :readonly="isEdit"
                name="guardian_pob"
                outlined
                dense
              ></v-text-field>
              <v-select
                v-model="g_ppdb_wali.data_wali.kewarganegaraan"
                :items="g_master_data.nationality"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.nationality')"
                :readonly="isEdit"
                name="nationality"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_wali.data_wali.pekerjaan"
                :label="$t('ppdb.personal.profession')"
                :readonly="isEdit"
                name="profession"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.data_wali.telepon"
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.phone_rules'),
                      b: $t('ppdb.rules.phone_valid_rules')
                    },
                    'phonenumberrequired'
                  )
                "
                :readonly="isEdit"
                name="phone_number"
                outlined
                dense
                maxlength="14"
                required
                @keypress="isNumberOnly($event)"
                ><template #label>
                  {{ $t("ppdb.personal.phone_number") }}
                  <span class="red--text"><strong>* </strong></span>
                </template></v-text-field
              >
              <v-select
                v-model="g_ppdb_wali.data_wali.hubungan_dengan_anak"
                :items="g_master_data.relations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.relation')"
                :readonly="isEdit"
                name="relation"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-menu
                ref="tgllahir"
                v-model="tglLahir"
                :close-on-content-click="false"
                :disabled="g_ppdb_wali.isOrtu"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="g_ppdb_wali.data_wali.tanggal_lahir"
                    :label="$t('ppdb.personal.dob')"
                    :disabled="isEdit"
                    name="dob"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="g_ppdb_wali.data_wali.tanggal_lahir"
                  :active-picker.sync="activePicker"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="
                    $refs.tgllahir.save(g_ppdb_wali.data_wali.tanggal_lahir)
                  "
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="g_ppdb_wali.data_wali.pendidikan_terakhir"
                :items="g_master_data.educations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.last_education')"
                :readonly="isEdit"
                name="last_education"
                outlined
                dense
              ></v-select>
              <v-select
                v-model="g_ppdb_wali.data_wali.penghasilan_perbulan"
                :items="g_master_data.incomes"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.monthly_income')"
                :readonly="isEdit"
                name="monthly_income"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_wali.data_wali.email"
                :label="$t('ppdb.personal.email')"
                :rules="
                  isValid(
                    {
                      a: $t('ppdb.rules.email_rules'),
                      b: $t('ppdb.rules.email_valid_rules')
                    },
                    'email'
                  )
                "
                :readonly="isEdit"
                name="email"
                outlined
                dense
                ><template #label>
                  {{ $t("ppdb.personal.email") }}
                  <span class="red--text"><strong>* </strong></span>
                </template>
              </v-text-field>
              <v-select
                v-model="g_ppdb_wali.data_wali.agama"
                :items="g_master_data.religions"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.religion')"
                :readonly="isEdit"
                name="religion"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="g_ppdb_wali.data_wali.informasi_lainnya"
                :label="$t('ppdb.personal.others_information')"
                :readonly="isEdit"
                autocomplete="off"
                auto-grow
                rows="1"
                row-height="15"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Wali -->

      <!-- Alamat Wali -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.guardian.guardian_address")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-5">
            <v-col cols="12">
              <v-row no-gutters class="mt-2">
                <v-text-field
                  v-model="g_ppdb_wali.alamat_wali.alamat"
                  :label="$t('ppdb.address.address')"
                  :readonly="isEdit"
                  name="address"
                  outlined
                  dense
                ></v-text-field>
                <v-btn
                  v-if="!isEdit"
                  :disabled="g_ppdb_wali.isOrtu"
                  tile
                  depressed
                  class="primary ml-4 px-2 rounded"
                  style="margin-top: 2px"
                  @click="openMapWali = !openMapWali"
                >
                  {{ openMapWali ? $t("app.close_map") : $t("app.open_map") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="openMapWali" class="mb-4">
              <MapBox :open-map="openMapWali" :set-data="setAlamatWali" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.provinsi"
                :label="$t('ppdb.address.province')"
                :readonly="isEdit || openMapWali"
                name="province"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.kecamatan"
                :label="$t('ppdb.address.sub_district')"
                name="distric"
                :readonly="isEdit || openMapWali"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.rt"
                :label="$t('ppdb.address.rt')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="isEdit"
                name="neighborhood_association"
                maxlength="3"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.kode_pos"
                :label="$t('ppdb.address.postal_code')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="isEdit || openMapWali"
                name="postal_code"
                maxlength="5"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.kota"
                :label="$t('ppdb.address.city')"
                :readonly="isEdit || openMapWali"
                name="city"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.kelurahan"
                :label="$t('ppdb.address.village')"
                :readonly="isEdit || openMapWali"
                name="sub_distric"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.rw"
                :label="$t('ppdb.address.rw')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="isEdit"
                name="citizens_association"
                maxlength="3"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_wali.alamat_wali.telepon_rumah"
                :label="$t('ppdb.address.telephone')"
                :rules="isValid($t('ppdb.rules.phone_valid_rules'), 'number')"
                :readonly="isEdit"
                name="home_telephone"
                maxlength="14"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Alamat Wali -->
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapBox from "@/components/MapBox";
import { isNumberOnly } from "@/utils/validate";

export default {
  components: { MapBox },
  props: {
    isValid: {
      type: Function
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["g_ppdb_wali", "g_master_data", "g_language", "g_darkmode"])
  },
  data: () => ({
    tglLahir: false,
    openMapWali: false,
    activePicker: null
  }),
  watch: {
    tglLahir() {
      setTimeout(() => (this.activePicker = "YEAR"));
    },
    openMapWali(newVal) {
      if (!newVal) this.$store.commit("PPDB_RESET_ALAMAT_WALI");
    }
  },
  methods: {
    isNumberOnly: evt => isNumberOnly(evt),
    getDataWali() {
      this.$store.commit("SET_DATA_WALI", this.g_ppdb_wali.isFatherOrMother);
    },
    setWaliIsOrtu() {
      this.$store.commit("SET_WALI_IS_ORTU");
    },
    setAlamatWali(data) {
      this.$store.commit("PPDB_SET_ALAMAT_WALI", data);
    }
  }
};
</script>
