<template>
  <v-container>
    <v-expansion-panels hover v-model="g_ppdb_ortu.open_panel" multiple>
      <!-- Data Ayah -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.parents.fathers_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-5">
            <v-col cols="12">
              <v-text-field
                v-model="g_ppdb_ortu.data_ayah.nama"
                :readonly="isEdit"
                :label="$t('ppdb.parents.personal.fathers_name')"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_ortu.data_ayah.tempat_lahir"
                :readonly="isEdit"
                :label="$t('ppdb.personal.pob')"
                outlined
                dense
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ayah.kewarganegaraan"
                :items="g_master_data.nationality"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                :readonly="isEdit"
                :label="$t('ppdb.personal.nationality')"
                item-value="value"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_ortu.data_ayah.pekerjaan"
                :readonly="isEdit"
                :label="$t('ppdb.personal.profession')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.data_ayah.telepon"
                :label="$t('ppdb.personal.phone_number')"
                :rules="
                  isValid($t('ppdb.rules.phone_valid_rules'), 'phonenumber')
                "
                :readonly="isEdit"
                maxlength="14"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ayah.hubungan_dengan_anak"
                :items="g_master_data.relations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :readonly="isEdit"
                :label="$t('ppdb.personal.relation')"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-menu
                ref="tgllahir"
                v-model="tglLahirAyah"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="g_ppdb_ortu.data_ayah.tanggal_lahir"
                    :label="$t('ppdb.personal.dob')"
                    :disabled="isEdit"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="g_ppdb_ortu.data_ayah.tanggal_lahir"
                  :active-picker.sync="activePicker"
                  :max="new Date().toISOString().substr(0, 10)"
                  :readonly="isEdit"
                  min="1950-01-01"
                  @change="
                    $refs.tgllahir.save(g_ppdb_ortu.data_ayah.tanggal_lahir)
                  "
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="g_ppdb_ortu.data_ayah.pendidikan_terakhir"
                :items="g_master_data.educations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                :readonly="isEdit"
                item-value="value"
                :label="$t('ppdb.personal.last_education')"
                outlined
                dense
              ></v-select>
              <v-select
                v-model="g_ppdb_ortu.data_ayah.penghasilan_perbulan"
                :items="g_master_data.incomes"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                :readonly="isEdit"
                item-value="value"
                :label="$t('ppdb.personal.monthly_income')"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_ortu.data_ayah.email"
                :rules="
                  isValid($t('ppdb.rules.email_valid_rules'), 'emailnorequired')
                "
                :readonly="isEdit"
                :label="$t('ppdb.personal.email')"
                outlined
                dense
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ayah.agama"
                :items="g_master_data.religions"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                :readonly="isEdit"
                item-value="value"
                :label="$t('ppdb.personal.religion')"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :label="$t('ppdb.personal.others_information')"
                v-model="g_ppdb_ortu.data_ayah.informasi_lainnya"
                auto-grow
                :readonly="isEdit"
                rows="1"
                row-height="15"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Ayah -->

      <!-- Alamat Ayah -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.parents.fathers_address")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row v-if="!isEdit">
            <v-col cols="12">
              <v-switch
                v-model="studentAddrs"
                :label="$t('ppdb.parents.copy_address_student')"
                :readonly="isEdit"
                @change="copyStudentAddrs"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12">
              <v-row no-gutters class="mt-2">
                <v-text-field
                  v-model="g_ppdb_ortu.alamat_ayah.alamat"
                  :readonly="isEdit"
                  :label="$t('ppdb.address.address')"
                  outlined
                  dense
                ></v-text-field>
                <v-btn
                  v-if="!isEdit"
                  :disabled="studentAddrs"
                  tile
                  depressed
                  class="primary ml-4 px-2 rounded"
                  style="margin-top: 2px"
                  @click="openMapAyah = !openMapAyah"
                >
                  {{ openMapAyah ? $t("app.close_map") : $t("app.open_map") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-6" v-show="openMapAyah">
              <MapBox :open-map="openMapAyah" :set-data="setAlamatAyah" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.provinsi"
                :readonly="studentAddrs || isEdit || openMapAyah"
                :label="$t('ppdb.address.province')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.kecamatan"
                :label="$t('ppdb.address.sub_district')"
                :readonly="studentAddrs || isEdit || openMapAyah"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.rt"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :label="$t('ppdb.address.rt')"
                :readonly="studentAddrs || isEdit"
                maxlength="3"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.kode_pos"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :label="$t('ppdb.address.postal_code')"
                :readonly="studentAddrs || isEdit || openMapAyah"
                maxlength="5"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.kota"
                :readonly="studentAddrs || isEdit || openMapAyah"
                :label="$t('ppdb.address.city')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.kelurahan"
                :readonly="studentAddrs || isEdit || openMapAyah"
                :label="$t('ppdb.address.village')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.rw"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :label="$t('ppdb.address.rw')"
                :readonly="studentAddrs || isEdit"
                outlined
                dense
                maxlength="3"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ayah.telepon_rumah"
                :rules="isValid($t('ppdb.rules.phone_valid_rules'), 'number')"
                :readonly="studentAddrs || isEdit"
                :label="$t('ppdb.address.telephone')"
                outlined
                dense
                maxlength="14"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Alamat Ayah -->

      <!-- Data Ibu -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.parents.mothers_sub")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row class="mt-5">
            <v-col cols="12">
              <v-text-field
                v-model="g_ppdb_ortu.data_ibu.nama"
                :readonly="isEdit"
                :label="$t('ppdb.parents.personal.mothers_name')"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_ortu.data_ibu.tempat_lahir"
                :readonly="isEdit"
                :label="$t('ppdb.personal.pob')"
                outlined
                dense
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ibu.kewarganegaraan"
                :items="g_master_data.nationality"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :readonly="isEdit"
                :label="$t('ppdb.personal.nationality')"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_ortu.data_ibu.pekerjaan"
                :readonly="isEdit"
                :label="$t('ppdb.personal.profession')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.data_ibu.telepon"
                :label="$t('ppdb.personal.phone_number')"
                :readonly="isEdit"
                maxlength="14"
                :rules="
                  isValid($t('ppdb.rules.phone_valid_rules'), 'phonenumber')
                "
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ibu.hubungan_dengan_anak"
                :items="g_master_data.relations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :readonly="isEdit"
                :label="$t('ppdb.personal.relation')"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-menu
                ref="tgllahir"
                v-model="tglLahirIbu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="g_ppdb_ortu.data_ibu.tanggal_lahir"
                    :label="$t('ppdb.personal.dob')"
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="g_ppdb_ortu.data_ibu.tanggal_lahir"
                  :active-picker.sync="activePicker"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="
                    $refs.tgllahir.save(g_ppdb_ortu.data_ibu.tanggal_lahir)
                  "
                  :readonly="isEdit"
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="g_ppdb_ortu.data_ibu.pendidikan_terakhir"
                :items="g_master_data.educations"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.last_education')"
                :readonly="isEdit"
                outlined
                dense
              ></v-select>
              <v-select
                v-model="g_ppdb_ortu.data_ibu.penghasilan_perbulan"
                :items="g_master_data.incomes"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.monthly_income')"
                :readonly="isEdit"
                outlined
                dense
              ></v-select>
              <v-text-field
                v-model="g_ppdb_ortu.data_ibu.email"
                :rules="
                  isValid($t('ppdb.rules.email_valid_rules'), 'emailnorequired')
                "
                :label="$t('ppdb.personal.email')"
                :readonly="isEdit"
                outlined
                dense
              ></v-text-field>
              <v-select
                v-model="g_ppdb_ortu.data_ibu.agama"
                :items="g_master_data.religions"
                :item-text="
                  g_language == 'id' ? 'description_ina' : 'description_eng'
                "
                item-value="value"
                :label="$t('ppdb.personal.religion')"
                :readonly="isEdit"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="g_ppdb_ortu.data_ibu.informasi_lainnya"
                :label="$t('ppdb.personal.others_information')"
                :readonly="isEdit"
                auto-grow
                rows="1"
                row-height="15"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Data Ibu -->

      <!-- Alamat Ibu -->
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header :class="!g_darkmode && 'grey lighten-3'">{{
          $t("ppdb.parents.mothers_address")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="!g_darkmode && 'grey lighten-5'">
          <v-row v-if="!isEdit">
            <v-col cols="12">
              <v-switch
                v-model="fatherAddrs"
                :label="$t('ppdb.parents.copy_address')"
                :readonly="isEdit"
                @change="copyFatherAddrs"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row no-gutters class="mt-2 d-flex">
                <v-text-field
                  v-model="g_ppdb_ortu.alamat_ibu.alamat"
                  :label="$t('ppdb.address.address')"
                  :readonly="fatherAddrs || isEdit"
                  outlined
                  dense
                ></v-text-field>
                <v-btn
                  v-if="!isEdit"
                  :disabled="fatherAddrs"
                  class="primary ml-4 px-2 rounded"
                  style="margin-top: 2px"
                  depressed
                  tile
                  @click="openMapIbu = !openMapIbu"
                >
                  {{ openMapIbu ? $t("app.close_map") : $t("app.open_map") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-6" v-show="openMapIbu">
              <MapBox :open-map="openMapIbu" :set-data="setAlamatIbu" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.provinsi"
                :label="$t('ppdb.address.province')"
                :readonly="fatherAddrs || isEdit || openMapIbu"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.kecamatan"
                :label="$t('ppdb.address.sub_district')"
                :readonly="fatherAddrs || isEdit || openMapIbu"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.rt"
                :label="$t('ppdb.address.rt')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="fatherAddrs || isEdit"
                maxlength="3"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.kode_pos"
                :label="$t('ppdb.address.postal_code')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="fatherAddrs || isEdit || openMapIbu"
                maxlength="5"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.kota"
                :label="$t('ppdb.address.city')"
                :readonly="fatherAddrs || isEdit || openMapIbu"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.kelurahan"
                :label="$t('ppdb.address.village')"
                :readonly="fatherAddrs || isEdit || openMapIbu"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.rw"
                :label="$t('ppdb.address.rw')"
                :rules="isValid($t('ppdb.rules.number_rules'), 'number')"
                :readonly="fatherAddrs || isEdit"
                outlined
                dense
                maxlength="3"
                @keypress="isNumberOnly($event)"
              ></v-text-field>
              <v-text-field
                v-model="g_ppdb_ortu.alamat_ibu.telepon_rumah"
                :label="$t('ppdb.address.telephone')"
                :rules="isValid($t('ppdb.rules.phone_valid_rules'), 'number')"
                :readonly="fatherAddrs || isEdit"
                maxlength="14"
                outlined
                dense
                @keypress="isNumberOnly($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- Alamat Ibu -->
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MapBox from "@/components/MapBox";
import { isNumberOnly } from "@/utils/validate";

export default {
  components: { MapBox },
  props: {
    isValid: {
      type: Function
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["g_ppdb_ortu", "g_master_data", "g_language", "g_darkmode"])
  },
  data: () => ({
    tglLahirAyah: false,
    tglLahirIbu: false,
    fatherAddrs: false,
    studentAddrs: false,
    openMapAyah: false,
    openMapIbu: false,
    activePicker: null
  }),
  watch: {
    tglLahirAyah() {
      setTimeout(() => (this.activePicker = "YEAR"));
    },
    tglLahirIbu() {
      setTimeout(() => (this.activePicker = "YEAR"));
    },
    openMapAyah(newVal) {
      if (!newVal)
        this.$store.commit("PPDB_RESET_ALAMAT_ORTU", { type: "ayah" });
    },
    openMapIbu(newVal) {
      if (!newVal)
        this.$store.commit("PPDB_RESET_ALAMAT_ORTU", { type: "ibu" });
    }
  },
  methods: {
    isNumberOnly: evt => isNumberOnly(evt),
    copyFatherAddrs() {
      this.$store.commit("PPDB_CP_ALAMAT_AYAH", this.fatherAddrs);
    },
    copyStudentAddrs() {
      this.$store.commit("PPDB_CP_ALAMAT_SISWA", this.studentAddrs);
    },
    setAlamatAyah(data) {
      this.$store.commit("PPDB_SET_ALAMAT_ORTU", { type: "ayah", val: data });
    },
    setAlamatIbu(data) {
      this.$store.commit("PPDB_SET_ALAMAT_ORTU", { type: "ibu", val: data });
    }
  }
};
</script>
