<template>
  <v-dialog
    v-model="modalRegisterDone"
    persistent
    max-width="500"
    transition="dialog-bottom-transition"
  >
    <v-card class="text-center rounded-xl">
      <div class="pa-4">
        <v-card-text>
          <img class="mb-3" src="@/assets/svg/home-icon-ppdb-done.svg" />
          <h2 class="title font-weight-bold">
            {{ $t("ppdb.register_done.registration_complete") }}
          </h2>
          <div class="my-4">
            <div class="body-2">
              {{ $t("ppdb.register_done.thanks") }}
            </div>
            <div v-if="email.length" class="body-2">
              {{ $t("ppdb.register_done.please_check") }}
              <span class="font-weight-bold">
                {{ getEmail }}
              </span>
              <span>
                {{ $t("ppdb.register_done.to_see") }}
              </span>
            </div>
            <div v-if="email.length" class="body-2">
              {{ $t("ppdb.register_done.username_password") }}
            </div>
            <div class="body-2">
              {{ $t("ppdb.register_done.please_note") }}
            </div>
            <div class="body-2 rounded-lg pa-3 mt-2 username-wrapper">
              <v-row justify="center">
                <span class="mx-5 text-truncate">
                  Username: <strong>{{ username }}</strong>
                </span>
                <span class="mx-5">
                  Password: <strong>{{ password }}</strong>
                </span>
              </v-row>
            </div>
          </div>
          <span class="caption primary--text">
            {{ $t("ppdb.register_done.please_contact") }}
            <strong>username</strong> / <strong>password</strong>
          </span>
          <span class="caption">
            {{ $t("ppdb.register_done.not_received_email") }}?
            <span
              :style="
                canResendEmail
                  ? 'color: #27ACE2; cursor: pointer'
                  : 'color: #616161'
              "
              @click="startCountdown()"
            >
              {{ $t("ppdb.register_done.resend_email") }}
            </span>
          </span>
          <br />
          <span>
            {{ countDown }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" align="center">
            <v-btn
              dark
              small
              class="gradient-primary-dark elevation-0 mr-5"
              @click="directTo('login')"
              width="100px"
            >
              {{ $t("app.login") }}
            </v-btn>
            <v-btn
              class="elevation-0"
              color="primary"
              outlined
              small
              width="100px"
              @click="directTo('')"
            >
              {{ $t("app.done") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </div>
    </v-card>
    <v-overlay :value="loadingEmail" :z-index="6">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment/moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);
import { resendEmail } from "@/api/admin/admin";

export default {
  props: {
    modalRegisterDone: {
      type: Boolean
    },
    modalRegisterDoneHandler: {
      type: Function
    },
    username: {
      type: String,
      default: "Exi"
    },
    password: {
      type: String,
      default: "ExiPassword"
    },
    email: Array,
    type: {
      type: String,
      default: "student"
    },
    person: Number
  },
  computed: {
    countDown() {
      return this.durationRemaining
        ? this.durationRemaining.format("mm:ss")
        : "";
    },
    getEmail() {
      return this.email.join(", ");
    },
    profileSchool() {
      return this.$store.getters.g_school;
    }
  },
  data() {
    return {
      canResendEmail: true,
      durationRemaining: null,
      loadingEmail: false,
      minutes: 2
    };
  },
  methods: {
    snackBar(isSuccess, message) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: message,
        color: isSuccess ? "success" : "error"
      });
    },
    async startCountdown() {
      this.canResendEmail = false;
      this.loadingEmail = true;
      try {
        const type =
          this.type === "student"
            ? "MAIL_PPDB_APPLIED_STUDENT"
            : "MAIL_PPDB_APPLIED_EMPLOYEE";
        const body = {
          email: this.email[0],
          type: type,
          person: this.person
        };
        const res = await resendEmail(body);
        if (res.data.code) {
          this.durationRemaining = moment.duration(2, "minutes");
          this.interval();
        } else {
          this.canResendEmail = true;
          this.snackBar(false, res.data.message);
        }
        this.loadingEmail = false;
      } catch (error) {
        this.loadingEmail = false;
        this.canResendEmail = true;
        console.error("startCountdown()\n", error);
      }
    },
    interval() {
      let countDown = setInterval(() => {
        let minute;
        let seconds;
        const oneSeconds = moment.duration({ seconds: 1 });
        this.durationRemaining = moment
          .duration(this.durationRemaining, "minutes")
          .subtract(oneSeconds);
        minute = this.durationRemaining.minutes();
        seconds = this.durationRemaining.seconds();
        if (minute === 0 && seconds === 0) {
          clearInterval(countDown);
          this.durationRemaining = null;
          this.canResendEmail = true;
        }
      }, 1000);
    },
    directTo(param) {
      if (param === "login") {
        this.$router.push({
          path: "/" + param,
          query: { username: this.username }
        });
      } else {
        this.$router.push("/" + param);
      }
      this.$store.commit("RESET_PPDB_SISWA");
      this.$store.commit("RESET_PPDB_GURU");
      this.modalRegisterDoneHandler();
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  color: #616161 !important;
}
.username-wrapper {
  border: 1.1px solid #27ace2;
  max-width: 90%;
  margin: 0 auto;
}
</style>
