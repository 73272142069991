<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-center mb-5">
          <div class="headline mx-auto">{{ $t("ppdb.statement.title") }}</div>
        </div>
        <p>{{ $t("ppdb.statement.section1") }}</p>
        <table>
          <tr v-if="g_ppdb_ortu.data_ayah.nama">
            <td>{{ $t("ppdb.parents.personal.fathers_name") }}</td>
            <td class="px-2">:</td>
            <td>
              <span class="font-weight-bold">{{
                g_ppdb_ortu.data_ayah.nama
              }}</span>
            </td>
          </tr>
          <tr v-if="g_ppdb_ortu.data_ibu.nama">
            <td>{{ $t("ppdb.parents.personal.mothers_name") }}</td>
            <td class="px-2">:</td>
            <td>
              <span class="font-weight-bold">{{
                g_ppdb_ortu.data_ibu.nama
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("ppdb.guardian.personal.name") }}</td>
            <td class="px-2">:</td>
            <td>
              <span class="font-weight-bold">{{
                g_ppdb_wali.data_wali.nama
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("ppdb.statement.child_name") }}</td>
            <td class="px-2">:</td>
            <td>
              <span class="font-weight-bold">{{
                g_ppdb_siswa.data_diri.nama_lengkap
              }}</span>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12">
        <p>
          {{ $t("ppdb.statement.section2") }}
        </p>
        <ol>
          <li>Menyetujui dan mendukung VISI dan MISI SEKOLAH</li>
          <li>
            Memenuhi kewajiban sebagai orangtua/wali murid dan turut mendukung
            proses kegiatan pembelajaran
          </li>
          <li>
            Bersedia hadir dalam setiap acara yang diadakan oleh pihak sekolah
          </li>
          <li>Mengijinkan anak kami mengikuti setiap kegiatan sekolah</li>
          <li>
            Mentaati semua peraturan dan tata-tertib yang berlaku di SEKOLAH
            serta hormat dan tunduk pada otoritas
          </li>
          <li>
            Jika didapati adanya pelanggaran dari hal tersebut di atas, maka
            saya bersedia menerima konsekuensi apapun dari pihak sekolah
          </li>
        </ol>
      </v-col>
      <v-col cols="12" class="mt-2">
        <span v-if="g_ppdb_siswa.data_diri.email"
          >* Informasi pendaftaran akan dikirimkan ke email:
          {{ g_ppdb_siswa.data_diri.email }}
          <span v-if="g_ppdb_wali.data_wali.email">
            dan {{ g_ppdb_wali.data_wali.email }}</span
          >
        </span>
      </v-col>
      <v-col cols="12">
        <v-checkbox v-model="g_ppdb_pernyataan.isAgree">
          <template v-slot:label>
            <div>
              {{ $t("ppdb.statement.section3") }}
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "g_ppdb_siswa",
      "g_ppdb_ortu",
      "g_ppdb_wali",
      "g_ppdb_pernyataan"
    ])
  }
};
</script>
